import LocaleInstance from 'web-client-lib/components/Locale';
import { rAF } from 'web-client-lib/components/utils';
import { generateStateKey, getStateKey, setStateKey } from './state';
import { html, render } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

export default class BaseRouter {

  constructor() {
    // Reference to the current route section
    this._currentSection = null;

    // Reference to the previous route section
    this._previousSection = null;

    // Current language (Locale) of app
    this._currentLanguage = null;

    // Reference to the current path of the app
    // this._location = window.location.pathname;

    // Flag to indicate if the partial content is being loaded in to .page_container.
    this._isSwapping = false;

    // Flag to indicate an error has occurred while fetching content.
    this._error = false;

    // Reference to the XHR request being made for the new partial content.
    this._request = null;

    // Flag to swap out the entire page and not just the content in the .page_container.
    this._fullpageSwap = false;

    // Reference to the new content that was fetched.
    this._newContent = null;

    // Scroll behavior section => position.
    this.scrollPosition = Object.create(null);

    // Reference to the container holding the partial to be swapped in and out.
    this._container = document.querySelector('.page_container');

    // Reference to the page wrap to set data attribute based on the currently loaded page.
    this._pageWrap = document.querySelector('.page_wrap');

    // Reference to the loading spinner;
    this._loadingSpinner = document.querySelector('.loading_container #searchSpinner');

    // Reference to locale
    this.locale = LocaleInstance();

    this.fetchTemplate = this.fetchTemplate.bind(this);
    this._onPopState = this._onPopState.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onClick = this._onClick.bind(this);
    this._onLoad = this._onLoad.bind(this);
    this._onSwapComplete = this._onSwapComplete.bind(this);

    this.addEventListeners();
    this.setupScroll();
  }

  setupScroll() {
    const protocolAndPath = window.location.protocol + '//' + window.location.host
    const absolutePath = window.location.href.replace(protocolAndPath, '')
    window.history.replaceState({ key: setStateKey(generateStateKey()) }, '', absolutePath);
  }

  get activeSection () {
    return this._currentSection;
  }

  /**
   * Transitions the app to the desired url given.
   * @param url - target path to transition to.
   * @param reload - force a page reload after updating location
   * @param noLang - dont append the current lang to front of the URL
   */
  go(url, reload, noLang) {
    // Using the Locale prepend the currentLanguage to the path.
    const parser = document.createElement('a');
    parser.href = url;

    if (!noLang) {
      parser.pathname = parser.pathname.startsWith(`/${this.locale.currentLanguage}`)
        ? `/${parser.pathname}` : `/${this.locale.currentLanguage}${parser.pathname}`;
    }

    if (window.location.pathname === parser.pathname) {
      return;
    }

    // Store the leaving pages scroll position.
    this.saveScrollPosition();

    try {
      this.pushState(parser.href);
    } catch (e) {
      window.location.assign(parser.href);
    }

    if (reload) {
      return window.location.reload();
    }

    return this._onChange();

  }

  /**
   * Updates the history with the statekey for scroll position.
   * @param {String} url URL to navigate too
   */
  pushState(url) {
    // persist the current histories state and generate new state key for incoming page.
    const stateCopy = Object.assign({}, history.state);
    stateCopy.key = getStateKey();
    window.history.pushState({ key: setStateKey(generateStateKey()) }, null, url);
  }

  /**
   * Force an error on the client. _isSwapping is reset to false to immediately load
   * error template.
   * @param {String} code HTTP client code cooresponding to the partial template
   */
  async error(code) {
    this._isSwapping = false;
    const module = await import('../controller/Controller');
    this.fetchTemplate(module, code, '');
  }

  /**
   * Redirect to the given url.
   * @param {String} url - destination URL
   */
  redirect(url, replace = false) {
    this._redirecting = true;
    let redirectUrl = new URL(url, window.location.origin);
    replace
    ? window.location.replace(redirectUrl)
    : window.location.href = redirectUrl;
  }

/**
   * Fetches a partial template to be loaded and swapped in.
   */
 async fetchTemplate(module, section, page) {
  try {
    if (module.remoteRender) {
      this._hideContent();
      await this._loadNewPath(section, page);
      if (this._isSwapping) {
        return;
      }
      this._isSwapping = true;
      this._swapContent(module, section, page);
    } else {
      // await this.authCheck();
      this._hideContent();
      await this._swapContent(module, section, page);
    }
  } catch (err) {
    this._handleError(err);
  }
}

  /**
   * Handler that receives the requested document.
   * @param event - The onload event from the XHR request for the document.
   */
  _onLoad(event) {
    if (event.target !== this._request) {
      return;
    }

    switch (this._request.status) {
      case 401:
        this._fullpageSwap = true;
        const referer = encodeURIComponent(`${location.pathname}${location.search}`);
        this.go(`/login?n=${referer}`, true, true);
        break;
      case 404:
      case 500:
        this._error = true;
      default:
        break;
    }

    this._newContent = event.target.response;
    this._request = null;
  }

  getModuleBySection(section) {
    throw Error("Base Router getModuleBySection must be implemented");
  }

  loadModule(module) {
    return new module.default();
  }

  /**
   * Retrieves the stored scroll position base on the active state key.
   */
  getScrollPosition() {
    const key = getStateKey();
    if (key) {
      return this.scrollPosition[key];
    }
  }

  /**
   * Stores the page offset of the leaving page.
   */
  saveScrollPosition() {
    const key = getStateKey();
    if (key) {
      this.scrollPosition[key] = [window.pageXOffset, window.pageYOffset];
    }
  }

  /**
   * Main handler for detecting a route change. Parses the url and
   * initializes a request the partial template for the new route.
   */
  async _onChange() {
    this._previousSection = this._currentSection;

    const path = window.decodeURIComponent(location.pathname);
    const splitPath = (path || '').slice(1).split('/');
    this._fullpageSwap = false;

    const routeDetails = await this.getModuleBySection(splitPath);
    const {module, page, section} = {...routeDetails};

    // _currentSection is the same as the transitioning section. If the section
    // has an update function, run function and return true to display content.
    // Else abort fetch as there is nothing to do.
    if (this._currentSection === section) {
      if (typeof(this._activeModule.update) === 'function') {
        this._activeModule.update(page);
        document.dispatchEvent(new CustomEvent('route-update', {
          bubbles: true,
          cancelable: true,
          detail: { section, page }
        }));
        return true;
      }
      return false;
    }

    const loadedModule = this.loadModule(module);

    this._redirecting = false;


    if ( document.body.classList.contains('hide-areas')) {
      document.body.classList.remove('hide-areas');
    }

    if (section === 'login' || this._fullpageSwap) {
      return true;
    }

    // transitioning section is not defined in our routes, abort.
    if (section === '404') {

      if (this._activeModule && this._activeModule.cleanup) {
        this._activeModule.cleanup();
      }

      this._previousSection = this._currentSection;
      this._currentSection = section;
      this._activeModule = loadedModule;
      document.body.focus();


      this.fetchTemplate(loadedModule, section, '');
      return false;
    }

    // If there is a section set perform any tasks before it
    // is transitioned out.
    if (this._activeModule && this._activeModule.cleanup) {
      this._activeModule.cleanup();
    }

    // Update the current section.
    this._currentSection = section;
    this._activeModule = loadedModule;

    document.dispatchEvent(new CustomEvent('route-change', {
      bubbles: true,
      cancelable: true,
      detail: section
    }));

    // Fire the request for the new content and hide the
    // existing content.
    this.fetchTemplate(loadedModule, section, page);

    return true;
  }

  /**
   * Fetches the new partial to load.
   * XHR request is used instead of fetch, b/c fetch doesn't
   * allow the request of documents, XHR does.
   */
  _loadNewPath(section, page) {
    return new Promise(function (resolve, reject) {
      const path = `/partial/${[section, page].filter(Boolean).join('/')}${location.search}`;

      this._request = new XMLHttpRequest();
      this._request.responseType = 'document';
      this._request.onload = event => {
        const responseUrl = new URL(this._request.responseURL);
        const originalUrl = new URL(path, location.origin);
        if (responseUrl.pathname.startsWith(originalUrl.pathname)) {
          this._onLoad(event);
          resolve();
        } else {
          // 302 redirect
          this.redirect(this._request.responseURL, true);
          reject();
        }
      };
      this._request.onerror = event => {
        reject(event);
      };
      this._request.ontimeout = event => {
        reject(event);
      };
      this._request.open('get', path, true);
      this._request.timeout = 10000;
      this._request.send();
    }.bind(this));
  }

  /**
   * Hides the content to be removed in the transition to the new
   * route.
   */
  _hideContent() {
    document.body.classList.add('hide-areas');
  }

 /**
   * FIXME: Cleanup swapContent, this is duplicate copy from BaseRouter.
   * Performs the swap of the previous section to the new partial
   * that was fetched. If the _fullpageSwap flag is set, the document
   * body is swapped out. Else just the content in the page_container
   * is swapped.
   */
  async _swapContent(module, section, page) {
    if (this._fullpageSwap) {
      document.body.innerHTML = this._newContent.body.innerHTML;
      this._container = document.querySelector('.page_container');
      this._pageWrap = document.querySelector('.page_wrap');
    } if (module.remoteRender) {
      const template = html`${ unsafeHTML(this._newContent.body.innerHTML) }`;
      render(template, this._container);
    }

    // Update canonical link to match page url.
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.href = `https://${location.hostname}${location.pathname}`;
    }

    if (!this._error) {
      // Run any initialization logic before revealing content.
      if (module && module.load) {
        // Waiting for callbackIn to resolve before swapping the content
        await module.load({
          section: section,
          title: page
        });
      }
    } else {
      this._activeModule.cleanup();
      this._activeModule = null;
      this._error = false;
    }

    if (!this._redirecting) {
      requestAnimationFrame(() => {
        document.body.classList.remove('hide-areas');
        this._onSwapComplete();
      });
    }
  }


 /**
   * Clean up callback when the content has been swapped.
   */
  _onSwapComplete() {
    this._isSwapping = false;
    if (this._loadingSpinner) {
      this._loadingSpinner.classList.remove('loading');
    }
    this._pageWrap.dataset['page'] = this._currentSection;

    // Check scroll position.
    const scrollPosition = this.getScrollPosition();
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition);
    } else {
      window.scrollTo(0, 1);
    }
  }


  /**
   * Contructs an error page if the template failed to load.
   * @param {Object} error Object describing the error when fetching template.
   */
  _handleError(error) {
    this._currentSection = null;
    this._error = false;
    let errorMsg = 'server_error_title';

    if (error && error.type === 'timeout') {
      errorMsg = '504_error_title';
    }

    this._container.innerHTML = `
      <header class="error_header">
        <div class="content container">
          <h2 class="page-title">${ this.locale.gettext(errorMsg) }</h2>
          <p class="error-text">${ this.locale.gettext('please_try_again') }</p>
          <button onclick="window.location.reload();"
            class="btn btn-primary btn-primary-emphasis btn-small auto-height">${ this.locale.gettext('try_again') }
          </button>
        </div>
      </header>
    `;

    window.scrollTo(0,1);
    rAF(() => {
      document.body.classList.remove('hide-areas');
      rAF(this._onSwapComplete);
    });
  }

  /**
   * Document page click handler. Intercepts clicks add handles internal
   * page requests manually.
   * @param event - Click event.
   */
  _onClick(event) {
    const target = (event.composedPath && typeof event.composedPath === 'function') ? event.composedPath()[0] : event.target;
    const node = target.closest('a');
    if (!node) return

    if (event.target.classList.contains('menu_icon')) {
      return;
    }

    if (node) {
      if (this._isBackLink(node)) {
        return;
      }
      if (!this._isExternalLink(node)) {
        event.preventDefault();
        this.go(node.href, node.hasAttribute('reload'), node.hasAttribute('no-lang'));
      }
    }
  }

  /**
   * Handler for the location popstate event. Prevents the browser
   * from handling the routing, allowing the app itself to handle routing.
   * @param {Event} event popstate event
   */
  _onPopState(event) {
    event.preventDefault();
    this.saveScrollPosition();
    if (event.state && event.state.key) {
      setStateKey(event.state.key);
    }
    rAF(() => {
      this._onChange(event);
    });
  }

  /**
   * Checks if the provided url is an external link or a POG link
   * @param url - Url to be checked
   */
  _isExternalLink (url) {
    if (url.href.startsWith('mailto:')) {
      return true;
    }

    const domainRe = /https?:\/\/((?:[a-zA-Z0-9-_\.]+)(:\d{2,4})?)/i;

    function domain(url) {
      return domainRe.exec(url)[0];
    }

    return url.pathname.startsWith('/pog') || location.origin !== domain(url.href);
  }

  _isBackLink (url) {
    return url.href.startsWith('javascript:');
  }

  /**
   * Updates the window (tab) title
   * @param {String} title
   * @param {String} section
   */
  _updateDocumentTitle(title='', section='') {
    if (!title) {
      section = section.charAt(0).toUpperCase() + section.slice(1);
      document.title = `${_env.site_title} - ${section}`;
    }
  }

  /**
   *
   */
  addEventListeners() {
    window.addEventListener('popstate', this._onPopState);
    window.addEventListener('load', this._onChange);
    document.addEventListener('click', this._onClick);
  }
}
