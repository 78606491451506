import { createStore, applyMiddleware, compose } from 'redux';
import { loadState } from './localStorage';
import service from '../services';
import rootReducer from '../reducers';
import provider from './provider';
import { middleware as initialState } from 'redux-async-initial-state';

const configureStore = () => {
  return createStore(
    rootReducer /* preloadedState */,
    {},
    compose(
      applyMiddleware(...[initialState(loadStore), service]),
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
};

// Load the persisted settings from idb
const loadStore = async (getState) => {
  const persistedState = await loadState();
  return {
    ...getState(),
    ...persistedState
  };
};

const store = configureStore();

export const connect = provider(store);
export default store;