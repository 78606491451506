import * as types from '../constants/actionTypes';

const initialState = {
  channelsHero: true
};

export default function siteReducer(state = initialState, action) {
  switch(action.type) {
    case types.SET_PREFERENCE:
      return {
        ...state,
        channelsHero: action.channelsHero
      }
    default:
      return state;
  }
  return state;
}