import * as types from '../constants/actionTypes';
import Events from 'web-client-lib/components/Events';

const initialState = {
  subscription: undefined,
  loading: false,
  apiToken: null,
  stateLoaded: false
};

export default function userReducer(state = initialState, action) {
  switch(action.type) {
    case types.STATE_LOADED:
      return {
        ...state,
        stateLoaded: true
      };
    case types.AUTH:
      return {
        ...state,
        loading: true
      };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        apiToken: action.payload.tkn,
        loading: false
      };
    case types.AUTH_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.AUTH_CANCEL:
      return {
        ...state,
        loading: false
      };
    case types.SET_SUBSCRIPTION:
      Events.register({'Has Access': action.subscription.access});
      Events.registerUserState(action.subscription);
      return {
        ...state,
        subscription: action.subscription,
        apiToken: action.subscription.tkn
      };
    default:
      return state;
  }
};