import * as types from '../constants/actionTypes';

const initialState = {
    videos: [],
    loading: false,
    error: null,
    next: '',
    page: 0,
    searchTerm: null,
    offset: 50
}

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case types.SEARCH_VIDEOS:
            return {
                ...state,
                loading: true,
                error: null,
                searchTerm: action.query
            };

        case types.SEARCH_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                next: (action.payload._links && action.payload._links.next) || null,
                videos: [...state.videos, ...action.payload.data],
                page: ++state.page
            };

        case types.SEARCH_VIDEOS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                videos: []
            };
        
        case types.CLEAR_SEARCH:
            return {
                ...initialState
            };

        case types.SEARCH_CANCEL:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}