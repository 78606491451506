import * as types from '../constants/actionTypes';

const initialState = {
  id: '',
  name: '',
  category: '',
  videos: [],
  next: '',
  page: 0,
  loading: false,
  loaded: false,
  error: null,
  offset: 50,
  swapLogo: false
};

export default function channelReducer(state = initialState, action) {
  switch(action.type) {
    case types.FETCH_CHANNEL:
      return {
        ...state,
        loading: true,
        error: null
      };

    case types.FETCH_CHANNEL_SUCCESS:

      return {
        ...state,
        loading: false,
        loaded: true,
        next: (action.payload._links && action.payload._links.next) || null,
        videos: [...state.videos, ...action.payload.data],
        page: ++state.page
      };

    case types.FETCH_CHANNEL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        next: '',
        list: [...state.videos]
      };

    case types.FETCH_CHANNEL_CANCEL:
      return {
        ...state,
        loading: false
      };

    case types.SELECT_CHANNEL:
      return {
        ...state,
        id: action.channel.id,
        name: action.channel.name,
        category: action.channel.category,
        videos: [],
        loading: false,
        page: 0,
        swapLogo: action.swapLogo
      };

    case types.CLEAR_CHANNEL:
      return {
        ...initialState
      };

    case types.RESET_CHANNEL:
      return {
        ...state,
        page: 0,
        videos: [],
        next: ''
      };

    default:
      return state;
  }
};