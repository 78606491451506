import { FETCH_CHANNEL } from '../constants/actionTypes';

const channelColors = {};

/**
 * Retrieves the list of channels from the store
 * @param {Object} store Instance of Redux store.
 * @returns {Array} list of channels
 */
export function getChannels (store) {
  const channels = store.getState().channels.list;
  return Object.values(channels).map(channel => {
    const { id, name, category, colors } = channel;
    return { id, name, category, colors };
  });
};

export function getChannelActiveLanguages (store) {
  return Object.assign([], store.getState().channels.activeLanguages);
};

/**
 * Retrieves the active selected channel
 * @param {Object} store Instance of Redux store.
 * @returns {Object} Selected channel
 */
export function getChannel (store) {
  return { ...store.getState().channel };
};

/**
 * Retrieves a channel by ID
 * @param {Object} store Instance of Redux store.
 * @param {String} id Video id to return.
 * @returns {Object} Channel
 */
export function getChannelById (store, id) {
  return store.getState().channels.list.find(channel => channel.id === id);
};

/**
 * Retrieves a channel by name
 * @param {Object} store Instance of Redux store.
 * @param {String} name Video name to return.
 * @returns {Object} Channel
 */
export function getChannelByName (store, name) {
  const normalizedName = decodeURI(name).replace(/[-_]/g, ' ').toLowerCase();
  return store.getState().channels.list.find(channel => channel.name.toLowerCase() === normalizedName);
};

/**
 * Retrieves a video fr
 * @param {Object} store Instance of Redux store.
 * @param {Number} index Index to video in channel video list
 * @returns {Object} Video in channel list at index
 */
export function getVideo (store, index) {
  const videos = store.getState().channel.videos;
  return typeof index !== 'undefined' ? videos[index] : videos[store.getState().video.videoIdx];
};

/**
 * Retrieves the video quality set by the user on their last quality change.
 * @param {Object} store Instance of the Redux store.
 */
export function getVideoQuality (store) {
  return store.getState().video.quality;
};

/**
 * Retrieves the video muted state.
 * @param {Object} store Instance of the Redux store.
 */
export function getVideoMuted (store) {
  return store.getState().video.muted;
};

/**
 * Retrieves a list of videos from the active channel.
 * @param {Object} store Instance of the Redux store.
 * @param {Number} count Number of videos to return.
 * @returns {Array} List of videos from the active channel from index 0 to count.
 */
export function getVideos (store, count) {
  const videos = store.getState().channel.videos.slice(0, count);
  return videos;
}

/**
 * Retrieves the next video given the videoId and channel. If the video list for
 * the channel is not loaded, it will fetch the video list for the channel and return
 * the first video in channel.
 * @param {Object} store Instance of Redux store.
 * @param {String} videoId Reference to the current video
 * @returns {Promise} Array of videos at length of count, following the given videoId.
 */
export function findNextVideos (store, videoId) {
  const channel = store.getState().channel;
  const videos = channel.videos;
  let videoIdx = store.getState().video.videoIdx;

  if (videos.length === 0) {
    if (channel.next != null && !channel.loading) {
      store.dispatch({
        type: FETCH_CHANNEL,
        id: channel.id
      });
      return [];
    }
  }

  if (videoIdx >= 0) {
    return videos.slice(videoIdx);
  } else {
    videoIdx = videos.findIndex(video => video.id === videoId);
    return videos.slice(videoIdx + 1);
  }

};

export function getUser (store) {
  return store.getState().user;
};


export function getAPiToken (store) {
  return store.getState().user.apiToken;
}

export function stateLoaded (store) {
  return store.getState().user.stateLoaded;
}

export function hasAccess (store) {
  const user = store.getState().user;
  if (_env.demo_enabled) {
    return true;
  }

  return user.subscription ? user.subscription.access : false;
}

export function showChannelsHero (store) {
  return store.getState().site.channelsHero;
}

export function getChannelColor(store, channel) {
  if (channel in channelColors) {
    return channelColors[channel];
  }
  const _channel = store.getState().channels.list.find(channelListItem => channelListItem.id === channel);
  channelColors[channel] = _channel.colors.normal;
  return channelColors[channel];                                                 
}