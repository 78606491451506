import * as types from '../constants/actionTypes';
import { UGZ_API } from '../constants/global';
import { getChannel, getChannelActiveLanguages, getAPiToken } from '../store/getters';

const getApiGenerator = next => async (url, name, headers) => {
  try {
    let response = await get(url, headers);
    let data = await response.json();

    return next({
      type: types[`${name}_SUCCESS`],
      payload: data
    });

  } catch (error) {
    return next({
      type: types[`${name}_FAILURE`],
      error
    });
  }

};

const service = store => next => action => {
  next(action);
  const getApi = getApiGenerator(next);
  const activeLanguages = getChannelActiveLanguages(store);
  const headers = new Headers();
  switch(action.type) {
    case types.AUTH:
      getApi('/subscription/has_access', 'AUTH', headers)
      break;
    case types.FETCH_CHANNELS:
      headers.append('x-api-key', getAPiToken(store));
      getApi(`${ UGZ_API }/channels/${activeLanguages.join(',')}`, 'FETCH_CHANNELS', headers);
      break;
    case types.FETCH_CHANNEL:
      headers.append('x-api-key', getAPiToken(store));
      const channel = getChannel(store);
      let url;
      if (channel.page > 0 && !channel.next) {
        return next({
          type: types['FETCH_CHANNEL_CANCEL']
        });
      } else if (channel.page === 0) {
        url = `${ UGZ_API }/media/${action.id}?l=${activeLanguages.join(',')}`;
      } else {
        url = `${ UGZ_API }/media/${ channel.id }?n=50&p=${ channel.next }&l=${activeLanguages.join(',')}`;
      }
      getApi(url, 'FETCH_CHANNEL', headers);
      break;
    case types.SEARCH_VIDEOS:
      headers.append('x-api-key', getAPiToken(store));
      const search = store.getState().search;
      let searchUrl;

      const searchParams = new URLSearchParams(location.search);
      let langParams;

      if (searchParams.has('l')) {
        langParams = searchParams.get('l');
      } else {
        langParams = activeLanguages.join(',');
      }

      if ((search.page > 0 && !search.next) || !action.query) {
        return next({
          type: types['SEARCH_CANCEL']
        });
      } else if (search.page === 0) {
        searchUrl = `${ UGZ_API }/search?q=${encodeURIComponent(action.query)}&l=${langParams}`;
      } else {
        searchUrl = `${ UGZ_API }/search?q=${encodeURIComponent(action.query)}&l=${langParams}&n=50&p=${ search.next }`;
      }

      getApi(searchUrl, 'SEARCH_VIDEOS', headers);
      break;
    default:
      break;
  }
}

/**
 * Performs a fetch with API key to retrieve data
 * @param {String} url Request url to API
 */
export const get = async (url, headers) => {
  try {
    const response = await fetch(url, { headers });
    if (response.status === 403) throw new Error('Error connecting to server');
    if (response.status === 404) throw new Error('No Channels not found');
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default service;