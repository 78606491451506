import { get, set } from 'idb-keyval';

export const loadState = async () => {
  try {
    const serializedState = await get('state');
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);

    // clean state data
    if (state.channels && (state.channels.loading || state.channels.error)) {
      delete state.channels.loading;
      delete state.channels.error;
    }

    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = async (state) => {
  try {
    const savedState = await loadState();
    const updatedState = Object.assign({}, savedState, state);
    const serializedState = JSON.stringify(updatedState);
    await set('state', serializedState);
  } catch (err) {

  }
};