import { css, html, LitElement } from 'lit-element';
import LocaleInstance from 'web-client-lib/components/Locale';

export default class ChannelTitle extends LitElement {

  static get styles() {
    return css`:host{display:inline-block;width:auto}h2{position:relative;color:#231f20;color:var(--grey-dark);font-size:clamp(.9em,4vw,1.2em);font-weight:700;text-transform:capitalize;margin:0;white-space:nowrap}:host(.track--channel_title) h2{font-size:clamp(1.3em,5vw,1.5em)}h2::after{content:'';background-color:var(--channel-color);position:absolute;height:6px;width:100%;bottom:-8px;left:0;z-index:-1}svg{flex:0 0 18px;transform:rotate(180deg);fill:var(--channel-color)}@media (min-width:768px){h2{font-size:clamp(.6em,4vw,.8em)}}@media (min-width:1024px){h2{font-size:clamp(.8em,4vw,1.1em)}}`;
  }

  static get properties() {
    return {
      name: {
        type: String
      }
    };
  }

  constructor() {
    super();
    this.name = '';
  }


  shouldUpdate(changedProperties) {
    return (changedProperties.has('name') && this.name);
  }

  render() {
    const locale = LocaleInstance();
    return html`<h2>${ locale.gettext(this.name + '_title') }</h2>`;
  }
}

customElements.define('channel-title', ChannelTitle);