import Events from 'web-client-lib/components/Events';

export default class Controller {

  constructor() {
    this.updating = false;
    this.remoteRender = true;
  }

  /**
   * Default callback for content loading
   */
  load(data) {
    let section = data.section.charAt(0).toUpperCase() + data.section.slice(1);
    section = section.replace(/(-|_)/g, ' ');

    if ('prevSection' in data && data.prevSection) {
      const previousSection =  (data.prevSection.charAt(0).toUpperCase() + data.prevSection.slice(1)).replace(/(-|_)/g, ' ');
      Events.track('Page Load', { Name: section, 'Previous Page': previousSection });
    } else {
      Events.track('Page Load', { Name: section });
    }

    this.setTitle(data.title ? data.title : section);
  }

  cleanup() {}

  setTitle(title) {
    document.title = `${_env.site_title} - ${title}`;
  }

  loadScript(url, async=false) {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = url;
      if (typeof async !== 'undefined') {
        script.async = async;
      }

      script.onerror = reject;
      script.onload = resolve;
      document.body.appendChild(script);
    });
  }

  loadCSS(url) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
  }
}