import { combineReducers } from 'redux';
import channels from './channelsReducer'
import channel from './channelReducer';
import video from './videoReducer';
import user from './userReducer';
import site from './siteReducer';
import search from './searchReducer';
import { outerReducer } from 'redux-async-initial-state';

const rootReducer = outerReducer(combineReducers({
  channels,
  channel,
  video,
  user,
  site,
  search
}));

export default rootReducer;