import * as types from '../constants/actionTypes';
import Events from 'web-client-lib/components/Events';

const initialState = {
  list: [],
  loading: false,
  error: null,
  activeLanguages: []
};

export default function channelsReducer(state = initialState, action) {
  switch(action.type) {
    case types.FETCH_CHANNELS:
      return {
        ...state,
        loading: true,
        error: null
      };

    case types.FETCH_CHANNELS_SUCCESS:

      return {
        ...state,
        loading: false,
        list: action.payload.data
      };

    case types.FETCH_CHANNELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    case types.UPDATE_ACTIVE_LANGUAGES:
      state.activeLanguages = state.activeLanguages || [];
      let activeLanguages;
      if (Array.isArray(action.locale)) {
        activeLanguages = new Set(action.locale);
      } else {
        activeLanguages = new Set(state.activeLanguages);
        action.active ? activeLanguages.add(action.locale) : activeLanguages.delete(action.locale);
      }

      Events.register({'Video Languages Setting': [...activeLanguages].toString() });

      return {
        ...state,
        list: [],
        activeLanguages: [...activeLanguages]
      };

    default:
      return state;
  }
}