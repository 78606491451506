import CookieConsent from '@App/scripts/base/components/CookieConsent';
import AppBaseController from '@App/scripts/base/controller/AppBaseController';
import { html, render as renderTemplate } from 'lit-html';
import LocaleInstance from 'web-client-lib/components/Locale';
import '../components/QcLogo';
import RouterInstance from '../components/Router';
import { FETCH_CHANNELS, UPDATE_ACTIVE_LANGUAGES } from '../constants/actionTypes';
import '../registerServiceWorker';
import store, { connect } from '../store';
import { getAPiToken, getChannelActiveLanguages, stateLoaded } from '../store/getters';
import Events from 'web-client-lib/components/Events';
import Search from '../components/Search';

export default class AppController extends AppBaseController {
  constructor() {
    super();

    // primaryLocaleize the locale for the user
    this.locale = LocaleInstance();
    this._renderActiveLanguages = this._renderActiveLanguages.bind(this, this.locale);
    this.render = this.render.bind(this);
    this.rotationHandler = this.rotationHandler.bind(this);

    // primaryLocaleize Popup
    if(_env.cookie_consent_popup){
      new CookieConsent();
    }

    // Router with store
    RouterInstance(store);

    // Lazy image load fallback.
    if (!('loading' in HTMLImageElement.prototype)) {
      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
      script.async = 1;
      document.body.appendChild(script);
    }

    if (screen && screen.orientation) {
      screen.orientation.onchange = this.rotationHandler;
    } else {
      window.addEventListener('orientationchange', this.rotationHandler);
    }
    this.rotationHandler();
  }

  async init () {
    await super.init();

    if (this.sideNav.initialized) {
      const toggleLanguageModal = document.getElementById('toggleLangModal');
      toggleLanguageModal.addEventListener('click', async () => {
        const LanguageModal = await import(/* webpackChunkName: "languagemodal" */ '../components/LanguageModal');
        const languageModal = new LanguageModal.default(store);
        languageModal.showModal();
      });
    }

    connect((state) => ({
      channels: state.channels,
      activeLanguages: state.channels.activeLanguages,
      subscription: state.user.subscription,
      apiToken: state.user.apiToken,
      stateLoaded: state.user.stateLoaded
    }))(this);

    this.deferredPrompt;

    // Listen for beforeinstallprompt to capture and provide install link
    // in sidenav
    // window.addEventListener('beforeinstallprompt', (e) => {
    //   // Prevent the mini-infobar from appearing on mobile
    //   e.preventDefault();

    //   // Stash the event so it can be triggered later.
    //   this.deferredPrompt = e;

    //   // Update UI notify the user they can install the PWA
    //   this.showInstallPromotion();
    // });

    // Get auth details for user
    const router = await RouterInstance();
    await router.authCheck();

    new Search();
  }

  render(props) {
    const { loading, list: channels, error } = store.getState().channels;
    const apiToken = getAPiToken(store);
    const loaded = stateLoaded(store);

    if (error) {
      const progressBar = document.querySelector('.loading');
      const errorMsg = document.createElement('div');
      errorMsg.classList.add('gutter');
      errorMsg.textContent = error;

      requestAnimationFrame(() => {
        progressBar.parentElement.appendChild(errorMsg);
        progressBar.style.display = 'none';
      });
      return;
    }

    // Wait until the users state is loaded from storage.
    if (!loaded) {
      return;
    }

    // A language selector to sideNav
    const activeLanguages = getChannelActiveLanguages(store);

    //  No language set default to user defined language
    if (activeLanguages.length === 0) {
      // get supported languages from sidenav.partial.html #supportedLangauges - CLIPS-1154
      const supportedLangauges = document.querySelector("#supportedLangauges")
      const langs = supportedLangauges ? supportedLangauges.value.split(",") : []
      // add current language if it's supported
      const current = langs.indexOf(this.locale.currentLanguage) >=0 ? [this.locale.currentLanguage] : []
      const defaultLanguages = Array.from(
        new Set([...current, ..._env.content_language_preference_order])
      );
      store.dispatch({
        type: UPDATE_ACTIVE_LANGUAGES,
        locale: defaultLanguages,
        active: true
      });

      return;
    }

    if (apiToken && !loading && channels.length === 0) {
      // Fetch available channels for locale.
      store.dispatch({ type: FETCH_CHANNELS });
    }

    if (props.channels.list.length !== channels.length) {

      // Remove splash
      this._removeSplash();

      if (this.sideNav.initialized) {
        this._renderActiveLanguages(activeLanguages);
        this._updateSideNavChannels(channels);
      }

    }
  }

  showInstallPromotion() {
    const appInstall = document.querySelector('#app_install')

    const installLink = html`<div class="side_nav__content app_links" style="margin-top:1em;padding:.84em;background-color:var(--primary-blue);display:flex"><img src="${ _env.static_url }/static/images/quickclips/icons/app.svg" style="width:48px;height:48px;margin-right:16px"><div><p style="font-size:.8em">Find what you need faster with our free app!</p><div style="display:flex"><a class="btn btn--general--fill" @click="${ this.hideInstallPromotion }" style="box-shadow:none;white-space:nowrap">${ this.locale.gettext('not_now') }</a> <a @click="${ this.install }" class="btn btn-flat">${ this.locale.gettext('install') }</a></div></div></div>`;

    renderTemplate(installLink, appInstall);
  }

  hideInstallPromotion() {
    const installLink = document.querySelector('#app_install');
    installLink.remove();
  }

  install() {
    this.hideInstallPromotion();

    // Show the install prompt
    this.deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }

  /**
   * Global orientation handler.
   */
  rotationHandler() {
    const currentAngle = this._angle();
    let orientation = '';

    if (currentAngle === 90 || currentAngle === 270 || currentAngle === -90) {
      // landscape
      orientation = 'Landscape';
    } else {
      // portrait
      orientation = 'Portrait';
    }

    Events.register({ 'Device Orientation': orientation });
  }

  /**
   * Helper function for calculating angle of screen. Defaults to portrait if not found
   * @returns current angle orientation of screen
   */
  _angle() {
    // iOS
    if (typeof window.orientation === 'number') {
      return window.orientation;
    }
    // Android
    if (screen && screen.orientation && screen.orientation.angle) {
      return screen.orientation.angle;
    }

    return 0;
  };

  _removeSplash() {
    const splash = document.querySelector('.splash');
    if (!splash) {
      return;
    }
    splash.addEventListener('transitionend', () => {
      splash.remove();
    }, { once: 1 });
    requestAnimationFrame(() => {
      splash.classList.add('splash--close');
    });
  }

  _renderActiveLanguages(locale, activeLanguages) {
    const activeLanguagesEl = document.getElementById('activeLanguages');
    const primaryLocale = activeLanguages.shift();
    let content;
    if (activeLanguages.length > 1) {
      content = locale.gettext(
        'menu_item_video_languages_details_plural',
        [locale.getLocaleDisplayName(primaryLocale), activeLanguages.length]);
    } else if (activeLanguages.length === 1) {
      content = locale.gettext(
        'menu_item_video_languages_details',
        [locale.getLocaleDisplayName(primaryLocale), activeLanguages.length]);
    } else {
      content = locale.getLocaleDisplayName(primaryLocale);
    }

    activeLanguagesEl.innerHTML = content;
  }

  _updateSideNavChannels(availableChannels) {
    const availableChannelsList = document.getElementById('availableChannels');
    const channels = html`${ availableChannels.map(channel => {
        return html`<li><a href="/channels/${ channel.category }">${ this.locale.gettext(channel.category + '_title') }</a></li>`;
      }) }`;

    renderTemplate(channels, availableChannelsList);

  }
}