import Controller from './Controller';

import NotificationInstance from 'web-client-lib/components/Notifications';
import LocaleInstance from 'web-client-lib/components/Locale';
import SideNav from 'web-client-lib/components/SideNav';
import Events from 'web-client-lib/components/Events';

export default class AppBaseController extends Controller {

  constructor() {
    super();

    //  Initialize all the mixPanel events
    Events.register({
      Carrier: _env.carrier,
      Carrier_name: _env.carrier_name,
      Country: _env.country,
      Locale: _env.locale,
      SiteId: _env.site_id,
      Version: _env.version,
      'Site Mode': _env.mode
    });

    if (localStorage.getItem('fl')) {
      Events.register({ 'First Visit': 'FALSE' });
    } else {
      Events.register({ 'First Visit': 'TRUE' });
      localStorage.setItem('fl', new Date());
    }
  

    this._appHeader = document.querySelector('.app_header');

    // Initialize the side nav.
    this._sideNav = new SideNav();

    if (this._appHeader) {
      this.addEventListeners();
    }
  }

  async init() {
    // Initialize site locale and translations
    await LocaleInstance().loadTranslations();

    // Initialize Notification bar.
    await NotificationInstance();
  }

  /**
   * Default callback for content cleanup
   */
  cleanup() {

  }

  get sideNav() {
    return this._sideNav;
  }

  addEventListeners() {
    // Event Listeners for header nav buttons.
    this._addMenuListeners();
  }

  /**
   *
   */
  _showLanguageDialog() {
    const locale = LocaleInstance();
    locale.showLanguageSelect();
  }

  /**
   *
   * @param {*} event
   */
  _headerListener(event) {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    if (distanceY > 50) {
      this._appHeader.classList.add('app_header__small');
    } else {
      this._appHeader.classList.remove('app_header__small');
    }
  }


/**
 *
 */
  _addMenuListeners() {
    const ctrl = this;
    const languageSelector = this._appHeader.querySelectorAll('.language_toggle'),
          menuToggle = this._appHeader.querySelector('#menu_toggle'),
          gameCategoryToggle = this._appHeader.querySelector('#category_toggle');

    menuToggle && menuToggle.addEventListener('click', _menuToggleHandler);
    gameCategoryToggle && gameCategoryToggle.addEventListener('click', _menuToggleHandler);

    /**
     * Handles the change event when the menu item is toggled.
     *
     * @param {ChangEvent} event - Event fired when the menu_item checkbox input is
     * checked
     */
    function _menuToggleHandler(event) {
      event.stopPropagation();
      if (this.checked) {
        menuToggle.addEventListener('sidenav:close', menuCloseHandler, {once: 1});
        document.addEventListener('click', menuCloseHandler);
      } else {
        document.removeEventListener('click', menuCloseHandler);
      }
    }

    const menuCloseHandler = (e) => {
      // e.preventDefault();
      let toggle = document.querySelector('.menu_toggle:checked');
      if (toggle) {
        toggle.checked = false;
      }
      document.removeEventListener('click', menuCloseHandler);
    };

    // If the language selector is in the header add click handler to launch
    // language select modal.
    if (languageSelector){
      languageSelector.forEach(oneLangSelector =>
        oneLangSelector.addEventListener('click', this._showLanguageDialog)
      );
    }
  }
}
