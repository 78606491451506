import { css, html, LitElement } from 'lit-element';
import { connect } from 'pwa-helpers';
import '../components/ChannelTitle';
import store from '../store';

export default class QCLogo extends connect(store)(LitElement) {
  static get styles() {
    return css`:host{display:flex;z-index:1}channel-title{position:absolute;transform:translate3d(42px,24px,0);animation-duration:.8s;animation-fill-mode:both;font-size:1.25em;width:auto;opacity:0;top:0;left:0}:host(.logo--expand) channel-title{animation-name:fadeIn}:host(.logo--collapse) channel-title{animation-name:fadeOut}svg{display:block;width:118px;height:auto;will-change:transform,opacity}:host([dir=rtl]) channel-title{transform:translate3d(-42px,24px,0);left:auto;right:12px}@keyframes fadeOut{from{opacity:1}to{opacity:0}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}@media (min-width:768px){channel-title{transform:translate3d(50px,21px,0)}:host([dir=rtl]) channel-title{transform:translate3d(-50px,21px,0)}}`;
  };

  static get properties() {
    return {
      title: { type: String }
    };
  }

  connectedCallback() {
    super.connectedCallback();
    if (document.documentElement.dir === 'rtl') {
      this.setAttribute('dir', 'rtl');
    }
  }

  stateChanged(state) {
    this.title = state.channel.category;

    if (state.channel.swapLogo && this.title) {
      this.shadowRoot.host.classList.add('logo--expand');
      this.shadowRoot.host.classList.remove('logo--collapse');
    } else {
      this.shadowRoot.host.classList.remove('logo--expand');
      this.shadowRoot.host.classList.add('logo--collapse');
    }
  }

  render() {
    return html`<slot name="logo"></slot><channel-title class="animated" .name="${ this.title || '' }"></channel-title>`;
  }
}

customElements.define('qc-logo', QCLogo);