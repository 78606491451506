import { SEARCH_VIDEOS, CLEAR_SEARCH } from '../constants/actionTypes';
import store from '../store';
import RouterInstance from '../components/Router';
import Events from 'web-client-lib/components/Events';
import { getChannelActiveLanguages } from '../store/getters';

export default class Search {
  constructor() {
    this.searchTerm = null;

    this.searchToggleIcon = document.getElementById('search_toggle');
    this.searchContainer = document.querySelector('.search_container');
    this.searchForm = document.getElementById('search_form');
    this.searchInputField = document.getElementById('search_input');
    this.searchBtn = document.getElementById('search_button');
    this.searchBackBtn = document.getElementById('search_back');
    this.searchInputClearBtn = document.getElementById('search_input_clear');
    this.appHeader = document.querySelector('.app_header');
    
    this.searchToggleSvg = document.querySelector('.search_toggle svg');
    this.searchBtnSvg = document.querySelector('.search_button svg');

    this.handleToggleSearch = this.handleToggleSearch.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
    this.handleSearchBack = this.handleSearchBack.bind(this);
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    
    this.searchBackBtn.addEventListener('click', this.handleSearchBack);
    this.searchToggleIcon.addEventListener('click', this.handleToggleSearch);
    this.searchInputField.addEventListener('keyup', this.handleInputFieldChange);
    this.searchForm.addEventListener('submit', this.handleFormSubmit);

    document.addEventListener('route-change', () => {
      const path = window.decodeURIComponent(location.pathname);
      const splitPath = (path || '').slice(1).split('/');
      let section = splitPath[1] || 'browse';

      if (section !== 'search') {
        this.searchContainer.classList.remove('search_container__visiable');
        this.searchBackBtn.classList.remove('search_back__visiable');
        document.querySelector('.logo').classList.remove('logo_hidden');
        document.querySelector('.menu').classList.remove('menu_hidden');
        // Remove inline transform style for toggle icon svg
        this.searchToggleSvg.removeAttribute('style');

        if (this.appHeader.classList.contains('app_header--search')){
          this.appHeader.classList.add('app_header--search-animate');
        }

       this.appHeader.addEventListener('transitionend', this.onTransitionEnd);
       this.appHeader.classList.remove('app_header--search');
      }

      if (section === 'browse') {
        this.searchForm.reset();
      }
    });

    this.searchForm.addEventListener('reset', () => {
      this.searchInputClearBtn.classList.remove('search_input_clear__visiable');
      this.searchBtn.classList.remove('search_button_active');
    });

    this.searchInputClearBtn.addEventListener('click', () => {
      this.searchInputField.value = '';
      this.searchInputField.focus();
      this.searchInputClearBtn.classList.remove('search_input_clear__visiable');
      this.searchBtn.classList.remove('search_button_active');
    });
  }

  async handleToggleSearch() {
    const router = await RouterInstance();
    this.appHeader.classList.add('app_header--search');
    this.appHeader.classList.add('app_header--search-animate');
    this.appHeader.addEventListener('transitionend', this.onTransitionEnd);

    const moveDistance = this.searchBtnSvg.getBoundingClientRect().x - this.searchToggleSvg.getBoundingClientRect().x;
    this.searchToggleSvg.style.transform = `translate3d(${moveDistance}px, 0, 0)`;

    this.searchToggleSvg.addEventListener('transitionend', () => {

       // Change url to search page
       router.go('/search');

       this.searchContainer.classList.add('search_container__visiable');
    }, { once: true });

    this.searchInputField.focus();
  }

  onTransitionEnd() {
    this.appHeader.classList.remove('app_header--search-animate');
    this.appHeader.removeEventListener('transitionend', this.onTransitionEnd);
  }

  async handleFormSubmit(e) {
    e.preventDefault();

    // Clear previous search results before getting the new search results
    store.dispatch({ type: CLEAR_SEARCH });

    this.searchTerm = e.target[0].value;

    if (this.searchTerm.trim().length === 0) {
        this.searchForm.reset();
        return;
    }

    const router = await RouterInstance();
    const activeLanguages = getChannelActiveLanguages(store);

    const path = window.decodeURIComponent(location.pathname);
    const splitPath = (path || '').slice(1).split('/');
  
    // If already in search route
    if (splitPath[1] === 'search') {
      // Update URL
      window.history.replaceState({}, null, `?q=${encodeURIComponent(this.searchTerm)}&l=${encodeURIComponent(activeLanguages.join(','))}`);
      // Search results based on the search term
      store.dispatch({ type: SEARCH_VIDEOS, query: this.searchTerm });
    } else {
      router.go(`/search?q=${encodeURIComponent(this.searchTerm)}&l=${encodeURIComponent(activeLanguages.join(','))}`);
    }
      
    window.scrollTo(0, 1);

    Events.track('Search', {
      'Search Text': this.searchTerm
    });
  }

  handleInputFieldChange(e) {

    if(e.target.value.length > 0) {
        this.searchBtn.classList.add('search_button_active');
        this.searchInputClearBtn.classList.add('search_input_clear__visiable');
    } else {
        this.searchBtn.classList.remove('search_button_active');
        this.searchInputClearBtn.classList.remove('search_input_clear__visiable');
    }

  }

  handleSearchBack() {

    // Clear search results only when on the search page
    const path = window.decodeURIComponent(location.pathname);
    const splitPath = (path || '').slice(1).split('/');

    if (splitPath[1] === 'search') {
        store.dispatch({ type: CLEAR_SEARCH });
        this.searchForm.reset();
    }

    this.searchContainer.classList.remove('search_container__visiable');

    window.history.back();
  }
}

