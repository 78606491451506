const shallowEqual = (objA, objB) => {
  const hasOwn = Object.prototype.hasOwnProperty
  if (Object.is(objA, objB)) return true
  if (typeof objA !== 'object' || objA === null ||
      typeof objB !== 'object' || objB === null) {
    return false
  }
  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)
  if (keysA.length !== keysB.length) return false
  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !Object.is(objA[keysA[i]], objB[keysA[i]])) {
      return false
    }
  }
  return true
};

const provider = (store) => (mapStateToProps) => (view) => {
  let props = mapStateToProps(store.getState());;
  const renderWrapper = () => {
    const newProps = mapStateToProps(store.getState());
    if (shallowEqual(props, newProps)) return;
    view.render(props);
    props = newProps;
  };
  view.unsubscribe = store.subscribe(renderWrapper);
  return renderWrapper;
};

export default provider;