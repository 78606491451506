import {getCookie} from 'web-client-lib/components/utils';

export default class CookieConsent {
  constructor() {
    this.closeButton = document.querySelector('.cookies_popup .close');
    this.popup = document.querySelector('.cookies_popup');

    this._hidePopup = this._hidePopup.bind(this);
    this._closeClicked = this._closeClicked.bind(this);

    this.closeButton.addEventListener('click', this._closeClicked);

    // Uses cookies to determine whether or not to show the popup
    if(getCookie("ugz_ac_ck")) {
      this._hidePopup();
    }
  }

  _hidePopup() {
    this.popup.classList.add('hidden');
  }

  _closeClicked() {
    this._hidePopup();
    document.cookie = "ugz_ac_ck=true;max-age=3155760000;path=/";
  }
}