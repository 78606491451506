import { html, render } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import BaseRouter from '../../base/components/Router';
import { SET_SUBSCRIPTION } from '../constants/actionTypes';

export default function RouterInstance(store) {
  if (typeof window._RouterInstance !== 'undefined') {
    return Promise.resolve(window._RouterInstance);
  }

  window._RouterInstance = new Router(store);

  return Promise.resolve(window._RouterInstance);
}

class Router extends BaseRouter {
  constructor(store) {
    super();
    this.store = store;

    // List of channels that do no support homepages. Results in a /404
    this._disabledChannelHomepages = ['business', 'cars', 'travel', 'technology'];
  }

  /**
   * Creates an instance of the view controller based on the module
   * provided. Each view controller is passed the store so it can
   * access the channels state.
   * @param {Object} module ViewController to be initialized
   */
  loadModule(module) {
    return new module.default(this.store);
  }

  async getModuleBySection(splitPath) {
    let section = splitPath[1] || 'browse';
    let module = null;
    let page = null;
    let videoId = null;
    switch(section) {
      case 'home':
        page = splitPath[2];

        if (this._disabledChannelHomepages.includes(page)) {
          module = await import('../../base/controller/Controller');
          section = '404';
        } else {
          module = await import(/* webpackChunkName: "homecontroller" */ '../controller/HomeController.js');
        }
        break;
      case 'browse':
        module = await import(/* webpackChunkName: "channelscontroller" */ '../controller/ChannelsController.js');
        break;
      case 'channels':
        page = splitPath[2];
        videoId = splitPath[4];
        if (videoId) {
          section = 'video';
          page = splitPath[4];
          module = await import(/* webpackChunkName: "videocontroller" */ '../controller/VideoController.js');
        } else if (page) {
          section = 'channel';
          module = await import(/* webpackChunkName: "channelcontroller" */ '../controller/ChannelController.js');
        } else {
          module = await import(/* webpackChunkName: "channelscontroller" */ '../controller/ChannelsController.js');
        }
        break;
      case 'subscription':
        module = await import(/* webpackChunkName: "subscriptioncontroller" */ '../controller/SubscriptionController.js');
        break;
      case 'contact-us':
        module = await import(/* webpackChunkName: "contactcontroller" */ '../../base/controller/ContactController.js');
        break;
      case 'search':
        module = await import(/* webpackChunkName: "searchcontroller" */ '../controller/SearchController.js');
        break;
      case 'about':
      case 'privacy-policy':
      case 'terms-and-conditions':
      case 'login':
        module = await import('../../base/controller/Controller');
        module.remoteRender = true;
        break;
      default:
        module = await import('../../base/controller/Controller');
        section = '404';
    }
    return {module, page, section};
  }

  async authCheck() {
    try {
      if (location.pathname.split('/').includes('login')) {
        const splash = document.querySelector('.splash');
        splash && splash.remove();
        return;
      }
      const response = await fetch('/subscription/has_access', {credentials: 'same-origin'});
      switch(response.status) {
        case 302:
          history.replaceState(null,null, response.url);
          break;
        case 401:
          this._fullpageSwap = true;
          const referer = encodeURIComponent(`${location.pathname}${location.search}`);
          this.go(`/login?n=${referer}`, true, false);
          break;
        case 403:
          console.log('not authorized');
        case 200:
          const subData = await response.json();
          this.store.dispatch({ type: SET_SUBSCRIPTION, subscription: subData });
      }
    } catch(e) {
      console.log(e);
    }
  }
}
