let _key = generateStateKey();

export function generateStateKey () {
  return Date.now().toFixed(3);
}

export function getStateKey () {
  return _key;
}

export function setStateKey (key) {
  return (_key = key);
}