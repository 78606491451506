import AppController from './controller/AppController';

// Set the rtl toggle to determine if the site is to be read from right-to-left.
window._rtl = document.documentElement.getAttribute('dir') === 'rtl';

// Hide address bar
window.addEventListener('load', () => {
  setTimeout(() => {
    window.scrollTo(0,1);
  }, 0);

  // init ACID
  // if (location.hostname !== 'localhost' && SharedState && acid) {
  //   SharedState.init('https://c.browse.us', '/acid');
  //   acid.goc();
  // }
});


new AppController().init();