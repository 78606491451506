export const SELECTED_VIDEO = 'SELECTED_VIDEO';
export const UPDATE_ACTIVE_LANGUAGES = 'UPDATE_ACTIVE_LANGUAGES';

export const FETCH_CHANNELS  = 'FETCH_CHANNELS';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';

export const SELECT_CHANNEL = 'SELECT_CHANNEL';
export const RESET_CHANNEL = 'RESET_CHANNEL';
export const CLEAR_CHANNEL = 'CLEAR_CHANNEL';

export const FETCH_CHANNEL = 'FETCH_CHANNEL';
export const FETCH_CHANNEL_SUCCESS = 'FETCH_CHANNEL_SUCCESS';
export const FETCH_CHANNEL_FAILURE = 'FETCH_CHANNEL_FAILURE';
export const FETCH_CHANNEL_CANCEL = 'FETCH_CHANNEL_CANCEL';

export const AUTH = 'AUTH';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_CANCEL = 'AUTH_CANCEL';

export const SELECT_VIDEO = 'SELECT_VIDEO';
export const CLEAR_SELECTED_VIDEO = 'CLEAR_SELECTED_VIDEO';
export const SELECT_VIDEO_QUALITY = 'SELECT_VIDEO_QUALITY';
export const SET_VIDEO_MUTED = 'SET_VIDEO_MUTED';

export const SEARCH_VIDEOS = 'SEARCH_VIDEOS';
export const SEARCH_VIDEOS_SUCCESS = 'SEARCH_VIDEOS_SUCCESS';
export const SEARCH_VIDEOS_FAILURE = 'SEARCH_VIDEOS_FAILURE';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SEARCH_CANCEL = 'SEARCH_CANCEL';

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

export const SET_PREFERENCE = 'SET_PREFERENCE';

export const STATE_LOADED = 'redux-async-initial-state/STATE_LOADING_DONE';