import * as types from '../constants/actionTypes';

const initialState = {
  videoIdx: undefined,
  quality: undefined,
  muted: false
};

export default function videoReducer(state = initialState, action) {
  switch(action.type) {
    case types.SELECT_VIDEO_QUALITY:
      return {
        ...state,
        quality: action.quality
      };

    case types.SET_VIDEO_MUTED:
      return {
        ...state,
        muted: action.muted
      };

    case types.SELECT_VIDEO:
      return {
        ...state,
        videoIdx: action.videoIdx
      };

    case types.CLEAR_SELECTED_VIDEO:
      return {
        ...state,
        videoIdx: undefined
      };

    default:
      return state;
  }
};